import JSEncrypt from './jsencrypt'

export default class Rsa {
  constructor() {
    this.jsencrypt = new JSEncrypt()
    // 设置公钥
    // this.jsencrypt.setPublicKey(`-----BEGIN PUBLIC KEY-----
    // MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQC970CwA1sGFQ8kWyxe+Db2U70s
    // xsua1b10gb9XKGJBTmQlYz7AA3L6aUQDPDk9WMFjpS1Oq0awDMsVvd/+E7qc/Qmd
    // 767jhDA3hOv5upMzeYhoByMJE2gh9Vd1DRPNQ0iBUQW6iRlCDtQIn9J2DuVTbUGt
    // WhC3+j+GQlNLNk/l+wIDAQAB
    // -----END PUBLIC KEY-----`);

    this.jsencrypt.setPublicKey(
      '-----BEGIN PUBLIC KEY-----MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQDq04c6My441Gj0UFKgrqUhAUg+kQZeUeWSPlAU9fr4HBPDldAeqzx1UR92KJHuQh/zs1HOamE2dgX9z/2oXcJaqoRIA/FXysx+z2YlJkSk8XQLcQ8EBOkp//MZrixam7lCYpNOjadQBb2Ot0U/Ky+jF2p+Ie8gSZ7/u+Wnr5grywIDAQAB-----END PUBLIC KEY-----'
    )
  }

  encode(str, confuse) {
    let timestamp = new Date().getTime()
    let enStr = timestamp ? timestamp + '|' + str : str
    let enCode = this.jsencrypt.encrypt(enStr)
    enCode = encodeURIComponent(enCode) // rsa+uri编码
    return encodeURIComponent(enCode)
  }

  decode(str, confuse) {
    return this.jsencrypt.decrypt(str) || ''
  }
}
